import React, { Component } from 'react';
import { Chart } from 'chart.js';
import Selectbd from '../bd/getDb';
import DatePicker from "react-datepicker";
import GaugeChart from 'react-gauge-chart'
import {HorizontalBar,Doughnut} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import '.././css/getDashboardSurvey.css'; // Crea un archivo CSS separado para los estilos

//Chart.register(ChartDataLabels);

class getDashboardSurveyv2 extends Component {
    constructor(){/**********/
        super();
        this.state = {
            date_desde : new Date(),
            date_hasta : new Date(),
            numero_encuestas:0,
            promedio:0,
            data_mejores:[],
            data_peores:[],
            data_mejoras:[],
            promotor:0,
            pasivo:0,
            detractor:0,
            por_promotor:0, 
            por_detractor:0,
            por_pasivo:0,
            sw_contactarse:0,
            options : {
              scales: {
                xAxes: [
                  {
                    ticks: {
                      min: 0, // Valor mínimo en el eje X
                      max: 10, // Valor máximo en el eje X
                      stepSize: 20, // Tamaño de paso en el eje X
                    },
                  },
                ],
              },
              responsive: true,
              plugins: {
                legend: {
                  display: true,
                  position: 'top',
                },
              },
            },
            groupedData: {},
            expanded: null,
        }
    }
    onChangeDesde = date => this.setState({ date_desde:date},()=>this.buscar_nps())

    onChangeHasta = date => this.setState({ date_hasta:date },()=>this.buscar_nps())

    formatDate=(date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
   
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
   
        return [year, month, day].join('-');
     }
    
    handleCalendarClose = () => {
        console.log("Calendar closed");
    }

    handleCalendarOpen = () => {
        console.log("Calendar opened");
    }
   

    componentDidMount() {
        this.renderCharts();
    }
    
      filterData = () => {
        const startDate = document.getElementById('start-date').value;
        const endDate = document.getElementById('end-date').value;
        alert(`Filtrando datos desde ${startDate} hasta ${endDate}`);
        // Aquí puedes agregar la lógica para filtrar los datos y actualizar los gráficos
      }
    
      renderCharts = () => {
  
        // NPS Gauge using gauge-chart
        const target = document.getElementById('npsGauge'); // your SVG element
        const gaugeOptions = {
          hasNeedle: true,
          needleColor: 'black',
          needleUpdateSpeed: 1000,
          arcColors: ['#FF0000', '#FFA500', '#FFFF00', '#9ACD32', '#008000'],
          arcDelimiters: [20, 40, 60, 80],
          rangeLabel: ['0', '100'],
          centralLabel: '45',
        }
        //gaugeChart(target, gaugeOptions).updateNeedle(45);
      }
      download_xls =()=>{
        const fecha_inicio = this.formatDate(this.state.date_desde)
        const fecha_fin = this.formatDate(this.state.date_hasta)
        const id_reporte= Number(this.state.id_reporte)
        const url= "https://www.grupomn.net/app/eldt/api/index.php/Webapp/getExportarSurvey/"+fecha_inicio+"/"+fecha_fin;
        console.log(url)
        window.open(url, "_self")
      }
      render() {
        const { groupedData, expanded } = this.state;
        const data_mejores= this.state.data_mejores
        const data_peores= this.state.data_peores
        let json_mejores  
        let json_peores  
        if(data_mejores.length>0){    
            const labels = data_mejores.map(local => local.nombre_local);
            const data = data_mejores.map(local => parseFloat(local.calificacion));
            const numberOfSurveys= data_mejores.map(local => parseFloat(local.encuestas));
            json_mejores = 
                    {
                "labels" : labels,
                "datasets": [
                    {
                    "label": "Local",
                    "data": data,
                    "borderColor": "white",
                    "backgroundColor": "#338d48" ,
                    "datalabels": {
                        color: 'black',
                        anchor: 'center',
                        align: 'center',
                        formatter: (value, context) => {
                          // Mostrar la calificación y número de encuestas
                          return `NPS: ${value} - Encuestas: ${numberOfSurveys[context.dataIndex]}`;
                        },
                      },
                    }
                ]
                }
        }

        if(data_peores.length>0){    
            const labels = data_peores.map(local => local.nombre_local);
            const data = data_peores.map(local => parseFloat(local.calificacion));
            const numberOfSurveys= data_peores.map(local => parseFloat(local.encuestas));
            json_peores = 
                    {
                "labels" : labels,
                "datasets": [
                    {
                    "label": "Local",
                    "data": data,
                    "borderColor": "white",
                    "backgroundColor": "#338d48",
                    "datalabels": {
                        color: 'black',
                        anchor: 'center',
                        align: 'center',
                        formatter: (value, context) => {
                          // Mostrar la calificación y número de encuestas
                          return `NPS: ${value} - Encuestas: ${numberOfSurveys[context.dataIndex]}`;
                        },
                      },
                    }
                ]
                }
        }
/* 0% ↑  45*/
        return (
          <div className="dashboard">
            <div className="date-filter">
              <label htmlFor="start-date">Desde:</label>
              <DatePicker selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} />
              <label htmlFor="end-date">Hasta:</label>
              <DatePicker selected={this.state.date_hasta} onChange={this.onChangeHasta} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} />
              <button onClick={()=>this.download_xls()}>Exportar a Excel</button>
            </div>
            <div className="gauge-container">
              <div className="gauge">
                <div className="gauge-title" style={{"color":"white"}}>NPS GENERAL</div>
                <GaugeChart id="gauge-chart2" 
                    nrOfLevels={10} 
                    percent={(Number(this.state.por_promotor)-Number(this.state.por_detractor))/100} 
                    colors={['#FF0000', '#FFFF00', '#00FF00']} 
                    textColor="red"
                    />
                <div className="gauge-score"></div>
                <div className="gauge-change"></div>
              </div>
              <div className="stat-item total-surveys">
                <div className="details">
                  <span>Total Encuestas</span>
                  <span>{this.state.numero_encuestas}</span>
                  <span className="range"></span>
                </div>
              </div>
            </div>
            <div className="stats">
              <div className="stat-item promoters">
                <img src="https://neola.cloud/img/avatar/cara_verde.png" alt="Promotores" />
                <div className="details">
                  <span>Promotores</span>
                  <span>{this.state.promotor} ({ this.state.por_promotor }%)</span>
                  <span className="range">10 y 9</span>
                </div>
              </div>
              <div className="stat-item passives">
                <img src="https://neola.cloud/img/avatar/cara_naranja.png" alt="Pasivos" />
                <div className="details">
                  <span>Pasivos</span>
                  <span>{this.state.pasivo} ({ this.state.por_pasivo }%)</span>
                  <span className="range">8 y 7</span>
                </div>
              </div>
              <div className="stat-item detractors">
                <img src="https://neola.cloud/img/avatar/cara_roja.png" alt="Detractores" />
                <div className="details">
                  <span>Detractores</span>
                  <span>{this.state.detractor} ({ this.state.por_detractor }%)</span>
                  <span className="range">6 a 0</span>
                </div>
              </div>
            </div>
            <div className="locations">
              <div >
                <h3>Locales con NPS Más Alto</h3>
                <div className="chart-container">
                {(data_mejores.length>0)?
                    <HorizontalBar
                        data={json_mejores} options={this.state.options} />:null}
                </div>
                <div className="disclaimer">
                  *Se pondera promotores (respuestas 10 y 9) <br />
                  En relación al número de encuestas por local
                </div>
              </div>
              <div>
                <h3>Locales con NPS Más Bajo</h3>
                <div className="chart-container">
                {(data_peores.length>0)?
                    <HorizontalBar
                        data={json_peores} options={this.state.options} />:null}
                </div>
                <div className="disclaimer">
                  *Se pondera detractores (respuestas de 6.99 a 0) <br />
                  En relación al número de encuestas por local
                </div>
              </div>
            </div>
            <div>
              <h2>Survey Resultados por Local</h2>
              <table className="table-survey">
                <thead>
                  <tr>
                    <th>Local</th>
                    <th>NPS Promedio</th>
                    <th>Encuestas</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupedData).map((local) => (
                    <React.Fragment key={local}>
                      <tr>
                        <td>{groupedData[local].local}</td>
                        <td>{groupedData[local].promedioCalificacion}</td>
                        <td>{groupedData[local].totalEncuestas}</td>
                        <td>
                          <button 
                            className="button-survey"
                            onClick={() => this.toggleExpand(local)}
                          >
                            {expanded === local ? 'Ocultar Detalle' : 'Mostrar Detalle'}
                          </button>
                        </td>
                      </tr>
                      {expanded === local && (
                        <tr className="accordion-expanded-survey">
                          <td colSpan="3">
                            <div className="accordion-content-survey">
                              <table className="table-survey-detail">
                                <thead>
                                  <tr>
                                    <th>Fecha</th>
                                    <th>NPS</th>
                                    <th>Observación</th>
                                    <th>IP</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {groupedData[local].items.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.fecha}</td>
                                      <td>{item.calificacion}</td>
                                      <td>{item.observacion}</td>
                                      <td>{item.ip}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div><br/><br/><br/><br/><br/><br/><br/>
          </div>
        );
      }


      toggleExpand(local) {
        this.setState((prevState) => ({
          expanded: prevState.expanded === local ? null : local,
        }));
      }

      
      buscar_nps =()=>{
        const desde = this.formatDate(this.state.date_desde)
        const hasta = this.formatDate(this.state.date_hasta)
        const select = [{
            type: 'consulta', data:
            [{
              table : "(SELECT count(*) as encuestas,cast((  (sum(if(calificacion=0,0.01,calificacion))/count(*))/10  ) as decimal(20,2)) as promedio FROM (SELECT * FROM bd_matriz_maitreneola.survey s1 group by if(s1.observacion='',uuid(),concat(s1.observacion,s1.fecha_creacion,s1.ip))) s  where fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' )",
              field :[],
              return:['encuestas','promedio']
            },
            {
                table : "(select * from (SELECT cast(sum(if(calificacion=0,0.01,calificacion))/count(*) as decimal(20,2)) as calificacion,count(*) as encuestas,nombre_local FROM (SELECT * FROM bd_matriz_maitreneola.survey s1 group by if(s1.observacion='',uuid(),concat(s1.observacion,s1.fecha_creacion,s1.ip))) s inner join `local` l on l.id_local=s.id_local where fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' group by s.id_local) a where calificacion>=9 order by calificacion desc limit 5)",
                field :[],
                return:['calificacion', 'encuestas', 'nombre_local']
            },
            {
                table : "(select * from (SELECT cast(sum(if(calificacion=0,0.01,calificacion))/count(*) as decimal(20,2)) as calificacion,count(*) as encuestas,nombre_local FROM (SELECT * FROM bd_matriz_maitreneola.survey s1 group by if(s1.observacion='',uuid(),concat(s1.observacion,s1.fecha_creacion,s1.ip))) s inner join `local` l on l.id_local=s.id_local where fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' group by s.id_local) a where calificacion<=6.99 order by calificacion asc limit 5)",
                field :[],
                return:['calificacion', 'encuestas', 'nombre_local']
            },{
                table : "(SELECT sum(if(calificacion>=9,1,0)) as promotor,sum(if(calificacion<=8.99 and calificacion>=7,1,0)) as pasivo,sum(if( calificacion<=6.99,1,0)) as detractor,cast((sum(if(calificacion>=9,1,0))/count(*))*100 as decimal(20,2)) as por_promotor, cast((sum(if(calificacion<=6.99,1,0))/count(*))*100 as decimal(20,2)) as por_detractor, cast((sum(if(calificacion>=7 and calificacion<=8.99,1,0))/count(*))*100 as decimal(20,2)) as por_pasivo  FROM  (select (sum(if(calificacion=0,0.01,calificacion))/count(*)) as calificacion from (SELECT * FROM bd_matriz_maitreneola.survey s1 group by if(s1.observacion='',uuid(),concat(s1.observacion,s1.fecha_creacion,s1.ip))) s where  fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' group by id_local) a  )",
                field :[],
                return:['promotor','pasivo','detractor','por_promotor','por_detractor','por_pasivo']
            },
            {
                table : "(SELECT count(*) as encuestas FROM (SELECT * FROM bd_matriz_maitreneola.survey s1 group by if(s1.observacion='',uuid(),concat(s1.observacion,s1.fecha_creacion,s1.ip))) s  where sw_contactarse=1 and fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' )",
                field :[],
                return:['encuestas']
              },{
                table : "(SELECT sum(sw_atencion) as sw_atencion, sum(sw_calidad) as sw_calidad, sum(sw_instalacion) as sw_instalacion, sum(sw_precio) as sw_precio, sum(sw_variedad) as sw_variedad FROM (SELECT * FROM bd_matriz_maitreneola.survey s1 group by if(s1.observacion='',uuid(),concat(s1.observacion,s1.fecha_creacion,s1.ip))) s  where fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' )",
                field :[],
                return:['sw_atencion', 'sw_calidad', 'sw_instalacion', 'sw_precio', 'sw_variedad']
              },
            ,{
                table : "(select nombre_local,mid(fecha_creacion,1,10) as fecha,if(calificacion=0,0.01,calificacion) as calificacion,observacion,ip from (SELECT * FROM bd_matriz_maitreneola.survey s1 group by if(s1.observacion='',uuid(),concat(s1.observacion,s1.fecha_creacion,s1.ip))) s  inner join bd_matriz_maitreneola.`local` l on l.id_local=s.id_local where calificacion>=0 and fecha_creacion>='"+desde+" 00:00:00' and fecha_creacion<='"+hasta+" 23:59:59' )",
                field :[],
                return:['nombre_local', 'fecha', 'calificacion', 'observacion', 'ip']
              },
            
            
            ]
          }]
          var result = Selectbd(select)
            if(result){
                result.then((value) => {
                    if(value[1].status==="ok"){
                        this.setState({
                            numero_encuestas : Number(value[1].result[0].encuestas) ,
                            promedio : Number(value[1].result[0].promedio) 
                        })
                    }else{
                        this.setState({
                            numero_encuestas : 0
                        })
                    }
                    if(value[2].status==="ok"){
                        this.setState({
                            data_mejores : value[2].result
                        })
                    }else{
                        this.setState({
                            data_mejores : []
                        })
                    }
                    if(value[3].status==="ok"){
                        this.setState({
                            data_peores : value[3].result
                        })
                    }else{
                        this.setState({
                            data_peores : []
                        })
                    }
                    if(value[4].status==="ok"){
                        this.setState({
                            promotor : Number(value[4].result[0].promotor),
                            pasivo : Number(value[4].result[0].pasivo),
                            detractor : Number(value[4].result[0].detractor),
                            por_promotor : Number(value[4].result[0].por_promotor), 
                            por_detractor : Number(value[4].result[0].por_detractor),
                            por_pasivo : Number(value[4].result[0].por_pasivo)
                        })
                    }else{
                        this.setState({
                            promotor : 0, pasivo: 0, detractor:0,por_detractor:0,por_promotor:0,por_pasivo:0
                        })
                    }
                    if(value[5].status==="ok"){
                        this.setState({
                            sw_contactarse : Number(value[5].result[0].encuestas)
                        })
                    }else{
                        this.setState({
                            sw_contactarse : 0
                        })
                    }
                    if(value[6].status==="ok"){
                        this.setState({
                            data_mejoras :value[6].result
                        })
                    }else{
                        this.setState({
                            data_mejoras : []
                        })
                    }
                    if(value[7].status==="ok"){
                          const data  = value[7].result
                          const groupedData = data.reduce((acc, curr) => {
                            if (!acc[curr.nombre_local]) {
                              acc[curr.nombre_local] = {
                                items: [],
                                totalCalificacion: 0,
                              };
                            }
                            acc[curr.nombre_local].items.push(curr);
                            acc[curr.nombre_local].totalCalificacion += parseInt(curr.calificacion);
                            return acc;
                          }, {});
                      
                          // Calcular la calificación promedio para cada local
                          let sortedData = Object.keys(groupedData).map((local) => {
                            const promedioCalificacion = (
                              groupedData[local].totalCalificacion / groupedData[local].items.length
                            ).toFixed(2);
                            const totalEncuestas = (
                               groupedData[local].items.length
                            );
                            return {
                              local,
                              promedioCalificacion: parseFloat(promedioCalificacion),
                              items: groupedData[local].items,
                              totalEncuestas
                            };
                          });
                      
                          // Ordenamos por la calificación promedio de menor a mayor
                          sortedData.sort((a, b) => a.promedioCalificacion - b.promedioCalificacion);
                      
                          this.setState({ groupedData: sortedData });
                    }


                    
                    this.renderCharts();
                })
            }
      }
}

export default getDashboardSurveyv2;
