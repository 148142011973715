import React, { Component } from 'react';
import {Doughnut,Bar} from 'react-chartjs-2';
import DatePicker from "react-datepicker";


/*const data = {
  labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      stack: 'Stack 0',
      data: [65, 59, 80, 81, 56, 55, 40.45]
    },
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      stack: 'Stack 0',
      data: [65, 59, 80, 81, 56, 55, 40.45]
    }
  ]
};*/

/*data = {
    datasets: [{
        data: [10, 20, 30]
    }],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: [
        'Red',
        'Yellow',
        'Blue'
    ]
};*/
class GetReporteCentralizado extends Component {
  state = {
    data :[],
    anio:"2025-2024",
    url_global:"https://grupomn.net/neola/index.php/webservices/",
    empresas:[],
    id_usuario_globales :0,
    id_reporte :0,
    date_desde : new Date(),
    date_hasta : new Date()
  }

  onChangeDesde = date => this.setState({ date_desde:date },()=>{
    this.consultarAPI( this.state.id_usuario_globales,this.state.anio)
  })

  onChangeHasta = date => this.setState({ date_hasta:date },()=>{
    this.consultarAPI( this.state.id_usuario_globales,this.state.anio)
  })

  handleCalendarClose = () => {
    console.log("Calendar closed");
  }

  handleCalendarOpen = () => {
      console.log("Calendar opened");
  }
  render(){

    let loading;
    if(this.state.loading){
      loading =<div id="loader" className="loader loader-default is-active" data-text=""></div>
    }

      return (
        <div className="" style={{ "backgroundColor": "white","width": "100%"}}>
          <div className="lbl_global" style={{ "backgroundColor": "white"}}>
            <div className="lbl_uno"  style={{ "backgroundColor": "white"}}>
                Año:<br/>
                <select className="select-css" value={this.state.anio} onChange={(e) => {this.handleChangeE(e)}}>
                  <option value="2025-2024">2025-2024</option>
                  <option value="2025-2023">2025-2023</option>
                  <option value="2025-2022">2025-2022</option>
                  <option value="2025-2021">2025-2021</option>
                  <option value="2025-2020">2025-2020</option>
                  <option value="2025-2019">2025-2019</option>
                  <option value="2025-2018">2025-2018</option>
                  <option value="2024-2023">2024-2023</option>
                  <option value="2024-2022">2024-2022</option>
                  <option value="2024-2021">2024-2021</option>
                  <option value="2024-2020">2024-2020</option>
                  <option value="2024-2019">2024-2019</option>
                  <option value="2024-2018">2024-2018</option>
                  <option value="2023-2022">2023-2022</option>
                  <option value="2023-2021">2023-2021</option>
                  <option value="2023-2020">2023-2020</option>
                  <option value="2023-2019">2023-2019</option>
                  <option value="2023-2018">2023-2018</option>
                  <option value="2022-2021">2022-2021</option>
                  <option value="2022-2020">2022-2020</option>
                  <option value="2022-2019">2022-2019</option>
                  <option value="2022-2018">2022-2018</option>
                  <option value="2021-2020">2021-2020</option>
                  <option value="2021-2019">2021-2019</option>
                  <option value="2021-2018">2021-2018</option>
                  <option value="2020-2019">2020-2019</option>
                  <option value="2020-2018">2020-2018</option>
                  <option value="2019-2018">2019-2018</option>
                </select>
                Empresa:<br/>
                {this.mostrarEmpresas()}
                Tipo de Reporte:<br/>
                <select className="select-css" value={this.state.id_reporte} onChange={(e) => {this.handleChangeR(e)}}>
                  <option value="0">Ventas Hoy</option>
                  <option value="5">Ventas por Filtros</option>
                  <option value="1">Ventas Totales</option>
                  <option value="2">Productos</option>
                  <option value="3">Tipo de Servicio</option>
                  <option value="4">Junior's</option>
                </select>
                {(Number(this.state.id_reporte)===5)?
                  <React.Fragment>
                    <br/>
                    <label>Desde</label><br/>
                    <DatePicker selected={this.state.date_desde} onChange={this.onChangeDesde} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} />
                    <br/><label>Hasta</label><br/>
                    <DatePicker selected={this.state.date_hasta} onChange={this.onChangeHasta} onCalendarClose={this.handleCalendarClose} onCalendarOpen={this.handleCalendarOpen} /><br/>
                  </React.Fragment>
                :null}
            </div>
            <div className="lbl_dos" style={{ "backgroundColor": "white"}}>
            {(Number(this.state.id_reporte)===0&&this.state.data!==null)?
              <div className="lbl_chart_2">
             
              <h2></h2>
              <h2>Ventas Hoy</h2>
                <div className="lbl_chart_2_sub">
                {this.state.data.map(subrubro => (
                  <React.Fragment>
                    {console.log(subrubro.grafico[0])}
                    <Doughnut
                      data={subrubro.grafico[0]}
                      width={100}
                      height={80}
                      options={{
                        maintainAspectRatio: false
                      }} />
                    </React.Fragment>
                  ) ) }
                </div>
              </div>
              : null}
            {(Number(this.state.id_reporte)!==0&&this.state.data!==null)?
            <React.Fragment>
            {this.state.data.map(subrubro => (
                <div className="lbl_chart_2">
                <h2>{subrubro.sucursal+""}</h2>
                <h2>Ventas {subrubro.labelf+""}</h2>
                  <div className="lbl_chart_2_sub">
                    <Bar key={subrubro.id}
                      data={subrubro.grafico[0]}
                      width={100}
                      height={80}
                      options={{
                        maintainAspectRatio: false
                      }}
                    />
                  </div>
                </div>
            ) ) }
            </React.Fragment>
              : null}
            </div>
            <div className="lbl_tres" style={{ "backgroundColor": "white"}}></div>
          </div>
          {loading}
        </div>
      )
  }


  handleChangeR(event) {
    let id_usuario= this.state.id_usuario_globales;
    let anio= this.state.anio;
    this.setState({
      id_reporte: event.target.value,
      data :[]
    },()=>{
      this.consultarAPI(id_usuario,anio)
    });
  }
  handleChangeE(event) {
    let id_usuario= this.state.id_usuario_globales;
    let anio= event.target.value;
    this.setState({
      anio: event.target.value
    },()=>{
      this.consultarAPI(id_usuario,anio)
    });
  }
  handleChangeE2(event) {
    let id_usuario= event.target.value;
    let anio= this.state.anio;
    this.setState({
      id_usuario_globales: event.target.value
    },()=>{
      this.consultarAPI(id_usuario,anio)
    });
  }
  consultarAPI =(id_usuario,anio) =>{
    const id_reporte= Number(this.state.id_reporte);
    if(id_usuario>0){
      let url;
      if(id_reporte===0){
        url =  this.state.url_global+"getdataVentasHoy/"+anio+"/"+id_usuario;
      }
      if(id_reporte===1){
        url =  this.state.url_global+"getdataVentasAnuales/"+anio+"/"+id_usuario;
      }
      if(id_reporte===2){
        url =  this.state.url_global+"getdataVentasDetalleAnuales/"+anio+"/"+id_usuario;
      }
      if(id_reporte===3){
        url =  this.state.url_global+"getdataVentasServiciosAnuales/"+anio+"/"+id_usuario;
      }
      if(id_reporte===4){
        url =  this.state.url_global+"getdataVentasPresentacionAnuales/"+anio+"/"+id_usuario;
      }
      if(id_reporte===5){

        const desde = this.formatDate(this.state.date_desde)
        const hasta = this.formatDate(this.state.date_hasta)
        url =  this.state.url_global+"getdataVentasAnualesF/"+anio+"/"+id_usuario+"/"+desde+"t"+hasta;
      }
      //this.setState({ data :null})
      //console.log(url)
        fetch( url)
        .then(res => res.json())
        .then((data) => {
          if(data[0].status==="0"){
            this.setState({ data :[]})
          }else{
            this.setState({ data })
          }
        })
        .catch(console.log)
    }
  }
  formatDate=(date)=> {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [month, day].join('-');
   }
  mostrarEmpresas = () => {

    const clientes = this.state.empresas;
    return (
      <React.Fragment>
        <select className="select-css" value={this.state.id_usuario_globales} onChange={(e) => {this.handleChangeE2(e)}}>
          <option value="0">Seleccione Empresa</option>
          {(clientes.length !== 0) ?
            clientes.map(cliente => (
              <option key={cliente.id_usuario_globales} value={cliente.id_usuario_globales}>{cliente.usuario}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
        </select>

      </React.Fragment>
    )
    //console.log("id_subrubro");
  }
  /*







  {this.state.data.map(subrubro => (
      <React.Fragment>
      <h2>Ventas de DELMAR</h2>
      <Bar key={subrubro.id[0]}
        data={subrubro.grafico[0]}
        width={50}
        height={50}
        options={{
          maintainAspectRatio: false
        }}
      />
      </React.Fragment>
  ) ) }*/


  componentDidMount (){
      const url=  this.state.url_global+"getEmpresaAPP";
        fetch( url)
        .then(res => res.json())
        .then((data) => {
          this.setState({ empresas:data })
        })
        .catch(console.log)
  }
}

export default GetReporteCentralizado;
